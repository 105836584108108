<template>
  <div>
    <el-card style="min-height: 45rem">
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-date-picker
                style="width: 100%"
                v-model="searchForm.time"
                type="daterange"
                range-separator="至"
                start-placeholder="创建时间范围开始日期"
                end-placeholder="创建时间范围结束日期">
            </el-date-picker>
          </el-col>
          <el-col :span="8">
            <div class="flex-def flex-zBetween">
              <el-button-group>
                <el-button @click="searchGo" type="primary">搜索</el-button>
                <el-button @click="searchReset">重置</el-button>
              </el-button-group>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="flex-def">
              <y-user-choose @change="selectBind" :disable="!(selectCodeIds.length > 0)">
                <el-button :disabled="!(selectCodeIds.length > 0)">批量绑定用户</el-button>
              </y-user-choose>
              <create-code style="margin-left: 10px" @created="load">
                <el-button type="success">添加空码</el-button>
              </create-code>
              <el-button style="margin-left: 10px" type="danger" @click="exportExcel">导出</el-button>
            </div>
          </el-col>
        </el-row>

      </div>
      <el-table @selection-change="selectionChange" :data="list">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="code" label="激活码"></el-table-column>
        <el-table-column prop="sn_code" label="激活码编号"></el-table-column>
        <el-table-column label="生成时间">
          <template #default="s">{{ s.row.created_at | date }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="s">
            <div class="flex-def">
              <y-user-choose @change="bindUid($event,s.row.id)">
                <el-button>绑定用户</el-button>
              </y-user-choose>
              <el-button style="margin-left: 10px" type="danger" plain @click="del(s.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import CreateCode from "@/view/card/plugin/cdkey/createCode.vue";
import YUserChoose from "@/components/user/YUserChoose.vue";

let SearchFormTpl = {
  keyword: "",
  time: [],
}
export default {
  components: {YUserChoose, CreateCode},

  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      size: 15,
      searchForm: {...SearchFormTpl},
      selectCodeIds:[],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    searchGo() {
      this.page = 1;
      this.list = [];
      this.load();
    },
    searchReset() {
      this.searchForm = {...SearchFormTpl}
      this.searchGo()
    },
    selectionChange(e){
      let arr = [];
      e.forEach(item=>{
        arr.push(item.id)
      })
      this.selectCodeIds = arr;
    },
    selectBind(u){
      this.$u.api.card.plugin.cdkey.uidBindCodeIds({
        uid:u.id,
        code_ids:this.selectCodeIds
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    bindUid(u,codeId){
      this.$u.api.card.plugin.cdkey.uidBindCodeIds({
        uid:u.id,
        code_ids:[codeId]
      }).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    del({id}) {
      this.$u.api.card.plugin.cdkey.del({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    exportExcel() {
      this.$u.api.card.plugin.cdkey.export({
        ...this.searchForm,
        is_creator:true,
      }).then(res => {
        window.open(res, "_blank")
      })
    },
    load() {
      this.$u.api.card.plugin.cdkey.search({
        page: this.page,
        ...this.searchForm,
        is_creator:true,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>